@font-face {
  font-family: "Roboto";
  src: local("Roboto"), url(../src/fonts/Roboto-Regular.ttf) format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "Roboto";
  src: local("Roboto"), url(../src/fonts/Roboto-Medium.ttf) format("truetype");
  font-weight: 500;
}

.cl-patient-details-phone-number-dropdown-container {
  max-height: 20rem;
}

// contact lens order patient details phone number select overide
.MuiPhoneNumber-flagButton {
  transform: scale(1.5);
  .flag {
    border-radius: 2px;
  }
}

.select-root {
  width: 100%;
}
